export const LOGIN_START = "LOGIN_START";
export const LAUNCH_LOGIN_MODAL = "LAUNCH_LOGIN_MODAL";
export const GET_USER_START = "GET_USER_START";
export const GET_USER_FAIL = "GET_USER_FAIL";

export const TOGGLE_GLOBAL_ERROR = "TOGGLE_GLOBAL_ERROR";
export const VERIFY_EMAIL_ADDRESS_START = "VERIFY_EMAIL_ADDRESS_START";
export const VERIFY_EMAIL_ADDRESS_SUCCESS = "VERIFY_EMAIL_ADDRESS_SUCCESS";
export const VERIFY_EMAIL_ADDRESS_FAIL = "VERIFY_EMAIL_ADDRESS_FAIL";
export const SUBMIT_LOGIN_START = "SUBMIT_LOGIN_START";
export const SUBMIT_LOGIN_SUCCESS = "SUBMIT_LOGIN_SUCCESS";
export const SUBMIT_LOGIN_FAIL = "SUBMIT_LOGIN_FAIL";
export const SAVE_SPREAD_START = "SAVE_SPREAD_START";
export const SAVE_SPREAD_SUCCESS = "SAVE_SPREAD_SUCCESS";
export const SAVE_SPREAD_FAIL = "SAVE_SPREAD_FAIL";
export const GET_CATEGORIES_SUCCESS = "GET_CATEGORIES_SUCCESS";
export const FORCE_LOGIN_MODAL = "FORCE_LOGIN_MODAL";
export const CLOSE_LOGIN_MODAL = "CLOSE_LOGIN_MODAL";
export const LOGOUT_USER = "LOGOUT_USER";
