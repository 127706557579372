import http from "./axios";

export function getProject(projectId) {
  if (projectId) return http.get(`project/${projectId}`);
  return http.get(`project`);
}
export function addProject(project) {
  return http.post(`project`, { ...project });
}
export function updateProject(projectId, project) {
  return http.put(`project/${projectId}`, { ...project });
}
