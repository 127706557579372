import React, { useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import Hidden from "@material-ui/core/Hidden";
import ListItem from "@material-ui/core/ListItem";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },

  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
    padding: "0 !important",
  },
  title: {
    flexGrow: 1,
    color: "#000000de !important",
    fontWeight: 500,
    fontSize: "1rem",
  },
  paper: {
    height: "35px !important",
    backgroundColor: "#eaeaea",
  },
  toolbar: {
    minHeight: "35px !important",
  },
}));

function VigressToolbar({
  spreads,
  projectSetup,
  projectSetupDone,
  history,
  location: { pathname },
}) {
  const classes = useStyles();
  const [openMenu, setOpenMenu] = useState(false);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setOpenMenu(open);
  };
  const list = (anchor) => (
    <div
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
      className="toolbarList"
    >
      <List>
        <ListItem>
          <Button
            color="primary"
            onClick={() => {
              history.push("/project");
            }}
            disabled={pathname && pathname.indexOf("/project") > -1}
          >
            Project Setup
          </Button>
        </ListItem>
        {projectSetupDone && spreads && spreads.length > 0 && (
          <>
            <ListItem>
              <Button
                color="primary"
                onClick={() => history.push("/progressupdate")}
                disabled={pathname && pathname.indexOf("/progressupdate") > -1}
              >
                Progress Update
              </Button>
            </ListItem>
            <ListItem>
              <Button
                color="primary"
                onClick={() => history.push("/records")}
                disabled={pathname && pathname.indexOf("/records") > -1}
              >
                Records
              </Button>
            </ListItem>
            <ListItem>
              <Button
                color="primary"
                onClick={() => history.push("/reports")}
                disabled={pathname && pathname.indexOf("/reports") > -1}
              >
                Reports
              </Button>
            </ListItem>
          </>
        )}
        <ListItem>
          <Button
            color="primary"
            onClick={() => {
              history.push("/dashboard");
            }}
            disabled={pathname && pathname.indexOf("/dashboard") > -1}
          >
            Dashboard
          </Button>
        </ListItem>
      </List>
    </div>
  );
  return (
    <div className="toolbarcontainer">
      <div className={classes.root}>
        <Paper className={classes.paper} position="static">
          <Toolbar className={classes.toolbar}>
            <Typography variant="h6" className={classes.title}>
              {projectSetup && projectSetup.projectName
                ? projectSetup.projectName
                : ""}
            </Typography>
            <Hidden xsDown implementation="css">
              <Button
                color="primary"
                onClick={() => {
                  history.push("/project");
                }}
                disabled={pathname && pathname.indexOf("/project") > -1}
              >
                Project Setup
              </Button>
              {projectSetupDone && spreads && spreads.length > 0 && (
                <>
                  <Button
                    color="primary"
                    onClick={() => history.push("/progressupdate")}
                    disabled={
                      pathname && pathname.indexOf("/progressupdate") > -1
                    }
                  >
                    Progress Update
                  </Button>
                  <Button
                    color="primary"
                    onClick={() => history.push("/records")}
                    disabled={pathname && pathname.indexOf("/records") > -1}
                  >
                    Records
                  </Button>
                  <Button
                    color="primary"
                    onClick={() => history.push("/reports")}
                    disabled={pathname && pathname.indexOf("/reports") > -1}
                  >
                    Reports
                  </Button>
                </>
              )}
              <Button
                color="primary"
                onClick={() => {
                  history.push("/dashboard");
                }}
                disabled={pathname && pathname.indexOf("/dashboard") > -1}
              >
                Dashboard
              </Button>
            </Hidden>
            <IconButton
              edge="start"
              className={classes.menuButton}
              color="primary"
              aria-label="menu"
              onClick={toggleDrawer(true)}
            >
              <MenuIcon />
            </IconButton>
          </Toolbar>
        </Paper>
        <Hidden smUp implementation="css">
          <SwipeableDrawer
            anchor="right"
            open={openMenu}
            onClose={toggleDrawer(false)}
            onOpen={toggleDrawer(true)}
          >
            {list()}
          </SwipeableDrawer>
        </Hidden>
      </div>
    </div>
  );
}

export default connect(({ project }) => {
  const { spreads, projectSetup, projectSetupDone } = project;
  return { spreads, projectSetup, projectSetupDone };
}, null)(withRouter(VigressToolbar));
