import React from "react";
import Paper from "@material-ui/core/Paper";

import "./VigressFooter.css";

export default function VigressFooter() {
  return (
    <Paper>
      <div className="footer">
        <span style={{ alignSelf: "center" }}>© 2021 Vigress, Inc.</span>
      </div>
    </Paper>
  );
}
