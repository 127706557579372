import { createStore, applyMiddleware, compose, combineReducers } from "redux";
import thunk from "redux-thunk";
import { createLogger } from "redux-logger";
import { createBrowserHistory } from "history";
import i18n from "../common/lang/locale_en.json";

import users from "../reducers/users";
import global from "../reducers/global";
import project from "../reducers/project";
import errors from "../reducers/errors";
import { setToken } from "../services/axios";
export const history = createBrowserHistory();
const debugMode = process.env.NODE_ENV !== "production";

const rootReducer = combineReducers({ users, global, errors, project });

const middlewares = [thunk];

// if (debugMode) {
middlewares.push(createLogger());
// }

const globalErrorsMiddleware =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    if (/_FAIL$/.test(action.type)) {
      // eslint-disable-next-line
      let errorMessage = i18n["app.common.generic.error"];
    }
    next(action);
  };

const setAuthToken =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    if (action.type === "SUBMIT_LOGIN_SUCCESS") {
      console.log("action.payload", action.payload);
      setToken(action.payload.token);
    }
    next(action);
  };
middlewares.push(setAuthToken);
middlewares.push(globalErrorsMiddleware);

const store = (initialState) => {
  const storeCreate = createStore(
    rootReducer,
    initialState,
    compose(applyMiddleware(...middlewares))
  );
  return storeCreate;
};

export default store;
