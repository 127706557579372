import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import AccountCircleOutlinedIcon from "@material-ui/icons/AccountCircleOutlined";
import Drawer from "@material-ui/core/Drawer";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    color: "var(--primary-color)",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    // flexGrow: 1,
    color: "var(--primary-color) !important",
    fontWeight: "normal",
    fontSize: "1.25rem",
    lineHeight: 1.6,
    letterSpacing: "0.0075em",
  },
  appBar: {
    height: "45px !important",
    backgroundColor: "#fff",
    boxShadow: "unset",
  },
  toolbar: {
    minHeight: "45px !important",
    justifyContent: "space-between",
  },
}));

function VigressHeader({ spreads, history }) {
  const classes = useStyles();
  const [openMenu, setOpenMenu] = useState(false);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setOpenMenu(open);
  };
  const list = (anchor) => (
    <div
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        {["Inbox", "Starred", "Send email", "Drafts"].map((text, index) => (
          <ListItem button key={text}>
            {/* <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon> */}
            <ListItemText primary={text} />
          </ListItem>
        ))}
      </List>
      <Divider />
      <List>
        {["All mail", "Trash", "Spam"].map((text, index) => (
          <ListItem button key={text}>
            {/* <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon> */}
            <ListItemText primary={text} />
          </ListItem>
        ))}
      </List>
    </div>
  );
  return (
    <div className={classes.root}>
      <AppBar className={classes.appBar} position="static">
        <Toolbar className={classes.toolbar}>
          {/* <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="menu"
            onClick={toggleDrawer(true)}
          >
            <MenuIcon />
          </IconButton> */}
          <span
            style={{ cursor: "pointer" }}
            onClick={() => {
              window.localStorage.removeItem("userId");
              window.localStorage.removeItem("projectId");
              history.push("/");
            }}
            className={classes.title}
            align="left"
          >
            {/* <Typography
              variant="h6"
              style={{ color: "var(--primary-color) !important" }}
            > */}
            Vigress
            {/* <`/Typography> */}
          </span>
          <Button
            color="primary"
            style={{ textAlign: "right" }}
            onClick={() => {
              window.localStorage.removeItem("userId");
              window.localStorage.removeItem("projectId");
              window.location.href = "/";
            }}
          >
            {/* <strong> */}
            <span style={{ marginRight: "0.5em", fontSize: "1rem" }}>
              Logout
            </span>
            {/* </strong> */}
            <AccountCircleOutlinedIcon
              size="small"
              style={{ marginBottom: "3px" }}
            />
          </Button>
        </Toolbar>
        <Drawer anchor="left" open={openMenu} onClose={toggleDrawer(false)}>
          {list()}
        </Drawer>
      </AppBar>
    </div>
  );
}

export default connect(({ project }) => {
  const { spreads } = project;
  return { spreads };
}, null)(withRouter(VigressHeader));
