import http from "./axios";

export function getCustomer(customerId) {
  if (customerId) return http.get(`customer/${customerId}`);
  return http.get(`customer`);
}
export function addCustomer(customer) {
  return http.post(`customers`, { ...customer });
}
export function updateCustomer(customerId, customer) {
  return http.put(`customer/${customerId}`, { ...customer });
}
