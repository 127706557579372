import http from "./axios";

export function getAllUpdates(projectId) {
  if (projectId) return http.get(`spreadUpdate/?projectId=${projectId}`);
  return http.get(`spreadUpdate`);
}
export function getSpreadUpdates(spreadId) {
  if (spreadId) return http.get(`spreadUpdate/?spreadId=${spreadId}`);
  return http.get(`spreadUpdate`);
}
export function addUpdate(spreadUpdate) {
  return http.post(`spreadUpdate`, { ...spreadUpdate });
}
export function updateSpread(spreadUpdate) {
  return http.put(`spreadUpdate/`, { ...spreadUpdate });
}

export function deleteUpdate(id) {
  return http.delete(`spreadUpdate/${id}`);
}
