import { TOGGLE_GLOBAL_ERROR } from "../actions/types";

const initialState = {
  globalError: false
};

export default function errors(state = initialState, action) {
  switch (action.type) {
    case TOGGLE_GLOBAL_ERROR: {
      return {
        ...state,
        globalError: action.payload
      };
    }
    default:
      return state;
  }
}
