import axios from "axios";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL || "/api/",
});

axiosInstance.interceptors.response.use(
  function (response) {
    return response.data;
  },
  (error) => {
    const expectedError =
      error.response &&
      error.response.status >= 400 &&
      error.response.status < 500;
    if (!expectedError) {
      let customMessage;
      if (
        error &&
        typeof error === "string" &&
        error.toLowerCase().indexOf("network") > -1
      )
        customMessage = "Please check your Internet Connection";
      alert(customMessage || error.response || error);
      console.log(error);
    }

    return Promise.reject(error.response || error);
  }
);
export function setToken(token) {
  axiosInstance.defaults.headers.common["x-auth-token"] = token;
}
export default {
  get: axiosInstance.get,
  post: axiosInstance.post,
  put: axiosInstance.put,
  delete: axiosInstance.delete,
};
