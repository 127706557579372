import { GET_CATEGORIES_SUCCESS } from "../actions/types";

const initialState = {
  categoriesList: [],
};

export default function global(state = initialState, action) {
  switch (action.type) {
    case GET_CATEGORIES_SUCCESS: {
      return {
        ...state,
        categoriesList: action.payload,
      };
    }
    case "SET_IS_PROCESSING": {
      return { ...state, isProcessing: action.payload };
    }
    default:
      return state;
  }
}
