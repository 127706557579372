import http from "./axios";
import { Helpers } from "../common/utils";

export function getUser(isPartner, token) {
  return http.get(
    `${isPartner ? "partners" : "users"}/me/?isPartner=${isPartner}`,
    {
      headers: { "x-auth-token": token },
    }
  );
}
export function getUserById(isPartner, id) {
  return http.get(`partners/${id}/?isPartner=${isPartner}`);
}
export function createPartner({ companyName, email, phone, password }) {
  return http.post(`partners`, { companyName, email, phone, password });
}
export function createUser({ email, password }) {
  return http.post(`users`, { email, password });
}
export function updateUser(user) {
  return http.put(`users/${user.id}`, { ...user });
}
export function updatePartner(partner) {
  return http.put(
    `partners/${partner.companyId}`,
    Helpers.convertModelToFormData(partner),
    {
      headers: { "Content-Type": "multipart/form-data" },
    }
  );
}
export function verifyEmailAddress(id, isPartner = true) {
  return http.put(
    `${isPartner ? "partners" : "users"}/${id}/verifyemailaddress`,
    {
      _id: id,
    }
  );
}

export function submitLogin(email, password, isPartner) {
  return http.post(`auth`, { email, password, isPartner });
}

export function forgotPassword(email, isPartner = false) {
  return http.post(`pwd`, { email, isPartner, action: "SEND_RESET" });
}

export function resetPassword({ resetId, newPwd, isPartner = false }) {
  return http.post(`pwd`, {
    resetId,
    newPwd,
    action: "RESET_PWD",
    isPartner,
  });
}

export function changePassword({
  email,
  currentPwd,
  newPwd,
  isPartner = false,
}) {
  return http.post(`pwd`, {
    email,
    newPwd,
    currentPwd,
    action: "CHANGE_PWD",
    isPartner,
  });
}
