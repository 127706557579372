import React from "react";

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }
  static getDerivedStateFromError() {
    return { hasError: true };
  }
  render() {
    if (this.state.hasError) {
      return (
        <>
          <div>
            <div className="text-center pt-5">
              <div variant="danger">
                <h3>Something went wrong. We are looking into these issues.</h3>
                <ul>
                  <li style={{ listStyle: "none" }}>
                    Mean while please check your internet connection and retry.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </>
      );
    }
    return this.props.children;
  }
}
