import { SAVE_SPREAD_SUCCESS } from "../actions/types";

const initialState = {
  projectSetup: {},
  spreads: [],
  selectedSpread: "All Segments",
  activityPercentMap: [],
};

export default function spread(state = initialState, action) {
  switch (action.type) {
    case SAVE_SPREAD_SUCCESS: {
      const { keyActivities } = action.payload;
      if (keyActivities && keyActivities.length > 0) {
        const newSpreads = state.spreads.map((s) => {
          const { activities } = s;
          if (activities && activities.length) {
            return {
              ...s,
              activities: keyActivities.map((keyA, i) => {
                if (activities[i]) return { ...activities[i], ...keyA };
                return keyA;
              }),
            };
          }
          return s;
        });
        localStorage.setItem("noUpdates", "0");
        return {
          ...state,
          ...action.payload,
          spreads: newSpreads,
        };
      }
      return {
        ...state,
        ...action.payload,
      };
    }
    case "SAVE_STATUS_SUCCESS": {
      const { spreads } = state;
      const spread = spreads.find(({ spreadDetails: s }) => {
        return s.spreadName === action.payload.spreadName;
      });
      let newSpread = { ...spread };
      if (spread.spreadUpdates) {
        newSpread = {
          ...newSpread,
          spreadUpdates: [...newSpread.spreadUpdates, action.payload],
        };
      } else {
        newSpread.spreadUpdates = [action.payload];
      }
      localStorage.setItem("noUpdates", "0");
      return {
        ...state,
        spreads: [
          ...state.spreads.map((s) => {
            if (s.spreadDetails.spreadName === action.payload.spreadName) {
              return newSpread;
            }
            return s;
          }),
        ],
      };
    }
    case "SET_SELECTED_SPREAD": {
      localStorage.setItem("noUpdates", "0");
      return { ...state, selectedSpread: action.payload };
    }
    case "SET_SELECTED_DASHBOARD_TYPE": {
      localStorage.setItem("noUpdates", "0");
      return { ...state, selectedDashboardType: action.payload };
    }
    case "SET_ACTIVITY_PERCENT_MAP": {
      localStorage.setItem("noUpdates", "0");
      return { ...state, activityPercentMap: action.payload };
    }
    case "SET_START_DATE": {
      localStorage.setItem("noUpdates", "0");
      return { ...state, startDate: action.payload };
    }
    case "SET_END_DATE": {
      localStorage.setItem("noUpdates", "0");
      return { ...state, endDate: action.payload };
    }
    case "SET_NO_OF_SEGMENTS": {
      localStorage.setItem("noUpdates", "0");
      return {
        ...state,
        projectSetup: { ...state.projectSetup, noOfSegments: action.payload },
      };
    }
    case "SET_PROJECT_PROGRESS": {
      localStorage.setItem("noUpdates", "0");
      return { ...state, projectProgress: action.payload };
    }
    case "COPY_TEMP_ACTIVITIES": {
      if (state.tempActivities && state.tempActivities.length) {
        localStorage.setItem("noUpdates", "0");

        return {
          ...state,
          keyActivities: [...state.tempActivities],
          tempActivities: null,
        };
      }
      return state;
    }
    case "SET_INITIAL_STATE": {
      localStorage.setItem("noUpdates", "1");
      const selectedSpread =
        action.payload?.spreads?.length === 1
          ? action.payload.spreads[0].spreadDetails.spreadName
          : "All Segments";
      return {
        ...action.payload,
        selectedSpread,
        activityPercentMap: [],
      };
    }
    case "IMPORT_STATUS_RECORDS": {
      const { spreads } = state;
      const spread = spreads.find(({ spreadDetails: s }) => {
        return s.spreadName === action.payload[0].spreadName;
      });
      let newSpread = { ...spread };
      if (spread.spreadUpdates) {
        newSpread = {
          ...newSpread,
          spreadUpdates: [...newSpread.spreadUpdates, ...action.payload],
        };
      } else {
        newSpread.spreadUpdates = [...action.payload];
      }
      localStorage.setItem("noUpdates", "0");

      return {
        ...state,
        spreads: [
          ...state.spreads.map((s) => {
            if (s.spreadDetails.spreadName === action.payload[0].spreadName) {
              return newSpread;
            }
            return s;
          }),
        ],
      };
    }
    default:
      return state;
  }
}
