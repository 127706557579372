import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import throttle from "lodash.throttle";
import { BrowserRouter, Route } from "react-router-dom";
import { IntlProvider } from "react-intl";
import store from "./store";
import { saveState } from "./store/localStorage";

import App from "./components/App";
import i18n from "./common/lang/locale_en.json";

import "./index.css";
import "@arcgis/core/assets/esri/themes/light/main.css";

const appStore = store({ projectSetup: {}, keyActivities: [], spreads: [] });

appStore.subscribe(
  throttle(() => {
    saveState({
      ...appStore.getState(),
    });
  }, 5000)
);

function Vigress() {
  return (
    <Provider store={appStore}>
      <IntlProvider
        locale={navigator.language}
        messages={i18n[navigator.language]}
      >
        <React.Fragment>
          <BrowserRouter>
            <Route component={App} />
          </BrowserRouter>
        </React.Fragment>
      </IntlProvider>
    </Provider>
  );
}

ReactDOM.render(<Vigress />, document.getElementById("root"));
