import React, { lazy, Suspense, useState } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import { loadState } from "../../store/localStorage";
import "./App.css";

import ErrorBoundary from "./ErrorBoundary";
import VigressHeader from "../VigressHeader";
import VigressToolbar from "../VigressToolbar";
import VigressFooter from "../VigressFooter";
import HomeHeader from "../HomeHeader";

const Dashboard = lazy(() => import("../Dashboard"));
const ProjectSetupContainer = lazy(() => import("../ProjectSetupContainer"));
const ProgressUpdate = lazy(() => import("../ProgressUpdate"));
const Records = lazy(() => import("../Records"));
const Reports = lazy(() => import("../Reports"));
const Home = lazy(() => import("../Home"));
const SignUp = lazy(() => import("../SignUp"));
const User = lazy(() => import("../User"));
const Login = lazy(() => import("../Login"));

// import Map from "../Map";
const theme = createMuiTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 691,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  palette: {
    // type: "dark",
    primary: {
      // Purple and green play nicely together.
      main: "#00b4f0",
      contrastText: "#fff",
    },
    secondary: {
      // This is green.A700 as hex.
      main: "#11cb5f",
    },
  },
  typography: {
    button: {
      "&:disabled": {
        color: "#000000de !important",
      },
    },
  },
});
function App({
  history,
  location: { pathname },
  profileCompleted,
  forceLogin,
}) {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  loadState().then((result) => {
    dispatch({ type: "SET_INITIAL_STATE", payload: { ...result } });
    setLoading(false);
  });
  if (loading) return <div>Loading....</div>;
  return (
    <React.Fragment>
      {/* <div id="myapproot">&nbsp;</div> */}
      <ThemeProvider theme={theme}>
        <ErrorBoundary>
          <Suspense fallback={<div>loading ...</div>}>
            <Switch>
              <Route exact path="/dashboard">
                <VigressHeader />
                <Dashboard />
              </Route>
              <Route exact path="/project">
                <VigressHeader />
                <VigressToolbar />
                <ProjectSetupContainer />
              </Route>
              <Route exact path="/progressupdate">
                <VigressHeader />
                <VigressToolbar />
                <div className="vigresscontainer">
                  <ProgressUpdate />
                </div>
              </Route>
              <Route exact path="/records">
                <VigressHeader />
                <VigressToolbar />
                <div className="vigresscontainer">
                  <Records />
                </div>
              </Route>
              <Route exact path="/reports">
                <VigressHeader />
                <VigressToolbar />
                <div className="vigresscontainer">
                  <Reports />
                </div>
              </Route>
              <Route exact path="/signup">
                <HomeHeader showSingUp={false} />
                <div className="vigresscontainer">
                  <SignUp />
                </div>
              </Route>
              <Route exact path="/newuser">
                <HomeHeader />
                <div className="vigresscontainer">
                  <User />
                </div>
              </Route>
              <Route exact path="/login">
                <HomeHeader showLogin={false} />
                <div className="vigresscontainer">
                  <Login />
                </div>
              </Route>
              <Route path="/">
                {/* <Redirect to="/dashboard" /> */}
                <Home />
              </Route>
            </Switch>
            <VigressFooter />
          </Suspense>
        </ErrorBoundary>
      </ThemeProvider>
    </React.Fragment>
  );
}
const mapStateToProps = ({ users: { profileCompleted, forceLogin } }) => ({
  profileCompleted,
  forceLogin,
});
export default connect(mapStateToProps, null)(App);
