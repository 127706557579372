import { setSelectedDashboardType } from "../actions/project";
import {
  getProject,
  updateProject,
  addProject,
  getSpreadUpdates,
} from "../services";
const initState = {
  projectSetup: {},
  spreads: [],
  selectedSpread: "All Segments",
  activityPercentMap: [],
};
export const loadState = async (projectId) => {
  try {
    // const serializedState = localStorage.getItem("state");
    // if (serializedState === null) {
    //   return undefined;
    // }
    // return JSON.parse(serializedState);
    const id = projectId || localStorage.getItem("projectId");
    if (!id) return initState;
    const data = await getProject(id);
    const { spreads } = data;
    const newSpreads = await Promise.all(
      spreads?.map(async (s) => {
        const spreadUpdates = await getSpreadUpdates(s._id);
        if (spreadUpdates) return { ...s, spreadUpdates };
        return s;
      }) || []
    );
    const selectedSpread =
      spreads?.length === 1
        ? spreads[0].spreadDetails?.spreadName
        : "All Segments";
    return data
      ? {
          ...data,
          spreads: [...newSpreads],
          selectedSpread,
          selectedDashboardType: "Actual",
        }
      : initState;
  } catch (err) {
    return initState;
  }
};

export const saveState = async (state) => {
  try {
    const { project } = state;
    const projectId = project._id || localStorage.getItem("projectId");
    const noUpdates = localStorage.getItem("noUpdates") || "1";
    if (projectId) {
      if (noUpdates === "0") await updateProject(projectId, project);
      return;
    }

    const { projectSetup } = project;
    if (!projectSetup.ready) {
      return;
    }
    delete project.projectSetup.ready;
    const userId = localStorage.getItem("userId");
    if (userId) {
      const data = await addProject({ ...project, userId });
      localStorage.setItem("projectId", data._id);
    }
  } catch (e) {
    console.log("something failed", e);
  }

  // try {
  //   const serializedState = JSON.stringify(state);
  //   localStorage.setItem("state", serializedState);
  // } catch {
  //   // ignore write errors
  // }
};
