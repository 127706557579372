import {
  addMinutes,
  getHours,
  getMinutes,
  setMinutes,
  setHours,
  setSeconds,
  setMilliseconds,
  format,
  isAfter,
  isBefore,
  isEqual,
  toDate,
} from "date-fns";
import { pointFromDistance } from "@arcgis/core/geometry/support/geodesicUtils";
import Point from "@arcgis/core/geometry/Point";
export function getFormData(obj) {
  if (obj) {
    const data = new FormData();
    for (let i = 0; i < Object.keys(obj).length; i++) {
      data.append(Object.keys(obj)[i], obj[Object.keys(obj)[i]]);
    }
    return data;
  }
  return obj;
}
export const isDateInBetween = (date, from, to, inclusivity = "[]") => {
  if (!["()", "[]", "(]", "[)"].includes(inclusivity)) {
    throw new Error("Inclusivity parameter must be one of (), [], (], [)");
  }

  const isBeforeEqual = inclusivity[0] === "[",
    isAfterEqual = inclusivity[1] === "]";

  return (
    (isBeforeEqual
      ? isEqual(from, date) || isBefore(from, date)
      : isBefore(from, date)) &&
    (isAfterEqual ? isEqual(to, date) || isAfter(to, date) : isAfter(to, date))
  );
  // return isWithinInterval(date, { start: from, end: to });
};
export class Helpers {
  static convertModelToFormData(data = {}, form = null, namespace = "") {
    console.log("before form data", data);
    let files = {};
    let model = {};
    for (let propertyName in data) {
      if (
        data.hasOwnProperty(propertyName) &&
        data[propertyName] instanceof File
      ) {
        files[propertyName] = data[propertyName];
      } else {
        model[propertyName] = data[propertyName];
      }
    }

    model = JSON.parse(JSON.stringify(model));
    let formData = form || new FormData();

    for (let propertyName in model) {
      if (!model.hasOwnProperty(propertyName) || !model[propertyName]) continue;
      let formKey = namespace ? `${namespace}[${propertyName}]` : propertyName;
      if (model[propertyName] instanceof Date)
        formData.append(formKey, model[propertyName].toISOString());
      else if (model[propertyName] instanceof File) {
        formData.append(formKey, model[propertyName]);
      } else if (model[propertyName] instanceof Array) {
        model[propertyName].forEach((element, index) => {
          const tempFormKey = `${formKey}[${index}]`;
          if (typeof element === "object")
            this.convertModelToFormData(element, formData, tempFormKey);
          else formData.append(tempFormKey, element.toString());
        });
      } else if (
        typeof model[propertyName] === "object" &&
        !(model[propertyName] instanceof File)
      )
        this.convertModelToFormData(model[propertyName], formData, formKey);
      else {
        formData.append(formKey, model[propertyName].toString());
      }
    }

    for (let propertyName in files) {
      if (files.hasOwnProperty(propertyName)) {
        formData.append(propertyName, files[propertyName]);
      }
    }
    return formData;
  }
}
//date utils
export function getStart(startDate, startTime) {
  const hours = getHours(new Date(startTime));
  const minutes = getMinutes(new Date(startTime));
  let newTime = setHours(new Date(startDate), hours);
  newTime = setMinutes(new Date(newTime), minutes);
  newTime = setSeconds(new Date(newTime), 0);
  newTime = setMilliseconds(new Date(newTime), 0);
  return newTime;
}
export function getEnd(startTime, durationHH, durationMM) {
  const minsInHours =
    durationHH && Number(durationHH) && Number(durationHH) > 0
      ? Number(durationHH) * 60
      : 0;
  const mins =
    durationMM && Number(durationMM) && Number(durationMM) > 0
      ? Number(durationMM)
      : 0;
  return addMinutes(new Date(startTime), minsInHours + mins);
}

export function displayAges(ageRangeST, ageRangeCN, ageRangeEN, ageRangeENYrs) {
  return `${ageRangeST ? ageRangeST : ""}${
    ageRangeCN
      ? ageRangeCN === "to"
        ? " -"
        : ageRangeCN === "plus"
        ? "+"
        : ""
      : ""
  }${ageRangeEN ? " " + ageRangeEN : ""} ${
    ageRangeENYrs
      ? ageRangeCN === "only"
        ? ageRangeENYrs + " only"
        : ageRangeENYrs
      : ""
  }`;
}

export function displayTime(date, time, durationHH, durationMM) {
  const startTime = format(new Date(getStart(date, time)), "hh:mm a");
  const endTime = format(
    new Date(getEnd(getStart(date, time), durationHH, durationMM)),
    "hh:mm a"
  );
  return `${startTime} - ${endTime}`;
}

export function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
const componentForm = {
  street_number: "short_name",
  route: "long_name",
  locality: "long_name",
  administrative_area_level_1: "short_name",
  country: "long_name",
  postal_code: "short_name",
};
export function getAddressFields(place) {
  const result = {
    addressLine1: "",
    city: "",
    state: "",
    country: "",
    postalCode: "",
  };
  let streetNumber = "",
    route = "";

  for (const component of place.address_components) {
    const addressType = component.types[0];
    if (componentForm[addressType]) {
      const val = component[componentForm[addressType]];
      switch (addressType) {
        case "street_number":
          streetNumber = val;
          break;
        case "route":
          route = val;
          break;
        case "locality":
          result.city = val;
          break;
        case "administrative_area_level_1":
          result.state = val;
          break;
        case "country":
          result.country = val;
          break;
        case "postal_code":
          result.postalCode = val;
          break;
        default:
          break;
      }
    }
  }
  result.addressLine1 = streetNumber + " " + route;
  return result;
}

export const getTotalDistanceMap = (spreads = []) => {
  let totalDistanceMap = {};
  spreads.map((s) => {
    const { activities, spreadDetails } = s;

    activities.map((a) => {
      if (totalDistanceMap[a.activityName]) {
        totalDistanceMap[a.activityName] += a.budgetedQuantity
          ? Number(a.budgetedQuantity)
          : spreadDetails.totalKP
          ? Number(spreadDetails.totalKP)
          : 1;
      } else {
        totalDistanceMap[a.activityName] = a.budgetedQuantity
          ? Number(a.budgetedQuantity)
          : spreadDetails.totalKP
          ? Number(spreadDetails.totalKP)
          : 1;
      }
      return a;
    });
    return s;
  });
  return totalDistanceMap;
};
const getActivtityPercents = (
  activityUpdates = [],
  activity,
  existingPercent,
  totalDistance
) => {
  if (activity.steps && activity.steps.length) {
    let activityPercentCompleted = 0;
    let activityDistanceCompleted = 0;
    // activityDistanceCompleted =0;
    const steps = activity.steps.map((s, i) => {
      const stepStatus = `${s.description} completed`;
      const stepCredit = s.ruleOfCredit
        ? Number(s.ruleOfCredit)
        : 100 / activity.steps.length;
      let distanceCompleted = 0;
      activityUpdates.every((curr) => {
        if (stepStatus === curr.status) {
          distanceCompleted += curr.quantity;
        }
        return curr;
      });

      activityPercentCompleted +=
        (distanceCompleted * stepCredit) / totalDistance;

      activityDistanceCompleted += (distanceCompleted * stepCredit) / 100;

      if (existingPercent) {
        const existingStep = existingPercent.steps.find(
          (es) => es.name === s.name
        );
        distanceCompleted += existingStep.completedQuantity;
      }
      const stepPercentCompleted = (distanceCompleted * 100) / totalDistance;
      return {
        ...s,
        budgetedQuantity: totalDistance,
        completedQuantity: distanceCompleted,
        percentCompleted: stepPercentCompleted,
        checked: true,
      };
    });
    if (existingPercent) {
      return {
        ...existingPercent,
        completedQuantity:
          existingPercent.completedQuantity + activityDistanceCompleted,
        percentCompleted:
          existingPercent.percentCompleted + activityPercentCompleted,
        steps,
      };
    }
    return {
      ...activity,
      budgetedQuantity: totalDistance,
      completedQuantity: activityDistanceCompleted,
      percentCompleted: activityPercentCompleted,
      checked: true,
      steps,
    };
  } else {
    const distanceCompleted = activityUpdates.reduce((prev, curr) => {
      return (prev += curr.quantity);
    }, 0);
    const percentCompleted = (distanceCompleted * 100) / totalDistance;
    if (existingPercent) {
      return {
        ...existingPercent,
        completedQuantity:
          existingPercent.completedQuantity + distanceCompleted,
        percentCompleted: existingPercent.percentCompleted + percentCompleted,
      };
    }
    return {
      ...activity,
      budgetedQuantity: totalDistance,
      completedQuantity: distanceCompleted,
      percentCompleted,
      checked: true,
    };
  }
};
export const reduceActivities = (spreadArray, startDate, endDate) => {
  let activityPercents = [];
  let spreadPercents = [];
  const distanceMap = getTotalDistanceMap(spreadArray);
  console.log("distanceMap", distanceMap);
  if (spreadArray.length > 0) {
    spreadArray.map((s) => {
      const { spreadUpdates, activities } = s;
      let filteredUpdates = null;
      if (spreadUpdates && spreadUpdates.length > 0) {
        filteredUpdates = [...spreadUpdates];
        if (startDate && endDate) {
          filteredUpdates = spreadUpdates.filter((s) => {
            return isDateInBetween(
              new Date(s.dateCompleted).setHours(0, 0, 0, 0),
              new Date(startDate).setHours(0, 0, 0, 0),
              new Date(endDate).setHours(0, 0, 0, 0)
            );
          });
        }
      }
      let spreadPercent = 0;
      activities.map((a) => {
        const activityUpdates = filteredUpdates
          ? filteredUpdates.filter((f) => f.activityName === a.activityName)
          : [];
        let existingPercentIndex = null;
        const existingPercent = activityPercents.find((ap, i) => {
          if (ap.activityName === a.activityName) {
            existingPercentIndex = i;
            return true;
          }
          return false;
        });
        const anActivityPercent = getActivtityPercents(
          activityUpdates,
          a,
          existingPercent,
          distanceMap[a.activityName]
        );
        console.log(anActivityPercent.completedQuantity);
        let activityDistanceCompletedForSpread =
          anActivityPercent.completedQuantity;
        if (existingPercent) {
          activityDistanceCompletedForSpread =
            anActivityPercent.completedQuantity -
            activityPercents[existingPercentIndex].completedQuantity;
          activityPercents[existingPercentIndex] = anActivityPercent;
        } else {
          activityPercents.push(anActivityPercent);
        }
        const weightage =
          (a.weightage ? Number(a.weightage) : 100 / activities.length) / 100;
        const budgetedQuantity = a.budgetedQuantity
          ? Number(a.budgetedQuantity)
          : Number(s.spreadDetails.totalKP);
        let activityPercentCompleted =
          (activityDistanceCompletedForSpread / budgetedQuantity) *
          100 *
          weightage;
        console.log(
          activityDistanceCompletedForSpread,
          budgetedQuantity,
          weightage,
          activityPercentCompleted
        );
        spreadPercent +=
          activityPercentCompleted > 100 ? 100 : activityPercentCompleted;

        return a;
      });
      spreadPercents.push(spreadPercent);
      return s;
    });
  }

  console.log("spreadPercents", spreadPercents);
  let projectProgress = 0;
  if (spreadPercents.length > 1) {
    projectProgress = spreadPercents.reduce((prev, curr, i) => {
      prev +=
        ((spreadArray[i].spreadDetails.weightage
          ? Number(spreadArray[i].spreadDetails.weightage)
          : 100 / spreadArray.length) /
          100) *
        curr;
      return prev;
    }, 0);
  } else if (spreadPercents.length === 1) {
    projectProgress = spreadPercents[0];
  }
  console.log("activityPercents", activityPercents);
  return { activityPercents, projectProgress: Math.round(projectProgress) };
};

export function formatNumber(num) {
  return ("" + num).replace(
    /(\d)(?=(?:\d{3})+(?:\.|$))|(\.\d\d?)\d*$/g,
    function (m, s1, s2) {
      return s2 || s1 + ",";
    }
  );
}

export const dataURLtoBlob = function (dataurl) {
  var parts = dataurl.split(","),
    mime = parts[0].match(/:(.*?);/)[1];
  if (parts[0].indexOf("base64") !== -1) {
    var bstr = atob(parts[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return URL.createObjectURL(new Blob([u8arr], { type: mime }));
  } else {
    var raw = decodeURIComponent(parts[1]);
    return URL.createObjectURL(new Blob([raw], { type: mime }));
  }
};
const derivePoint = (fromPoint, distance, azimuth) => {
  if (azimuth < 0) {
    azimuth += 360;
  }
  const destination = pointFromDistance(
    new Point({
      x: fromPoint[0],
      y: fromPoint[1],
    }),
    distance,
    azimuth
  );
  const { latitude, longitude } = destination;
  return [longitude, latitude];
};
export const createKPPoints1 = (spreadPaths, prevSpreadGap = 0, spreadName) => {
  const kps1 = [];
  if (spreadPaths && spreadPaths.length) {
    let kpId = 1,
      leftOutDistance = prevSpreadGap;
    spreadPaths.map((sp, spi) => {
      if (spi === spreadPaths.length - 1) return sp;

      const wholeKP = parseInt(
        (spreadPaths[spi + 1].distanceInMeters + leftOutDistance * 1000) / 1000
      );
      const nextLeftOut =
        (spreadPaths[spi + 1].distanceInMeters + leftOutDistance * 1000) /
          1000 -
        wholeKP;

      const azimuth = spreadPaths[spi + 1].azimuth;
      [...Array(wholeKP).keys()].map((wk, wki) => {
        const distance = wki === 0 ? (wk + 1 - leftOutDistance) * 1000 : 1000;
        // const distance = 1000;

        const fromPoint = wki === 0 ? sp.point : kps1[kps1.length - 1].point;
        const kpPoint = derivePoint(fromPoint, distance, azimuth);
        kps1.push({ point: kpPoint, spreadName });
        kpId += 1;
        return wk;
      });
      leftOutDistance = nextLeftOut;
      // spreadPaths[spi + 1].distanceInMeters / 1000 -
      // parseInt(spreadPaths[spi + 1].distanceInMeters / 1000);
      return sp;
    });
  }

  return kps1;
};
