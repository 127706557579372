import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import AccountCircleOutlinedIcon from "@material-ui/icons/AccountCircleOutlined";
import Drawer from "@material-ui/core/Drawer";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Container from "@material-ui/core/Container";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    color: "var(--primary-color)",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    color: "var(--primary-color) !important",
    fontWeight: "normal",
  },
  appBar: {
    height: "45px !important",
    backgroundColor: "#fff",
    boxShadow: "unset",
  },
  toolbar: {
    minHeight: "45px !important",
  },
}));

function HomeHeader({ showLogin = true, showSingUp = true }) {
  const classes = useStyles();
  const [openMenu, setOpenMenu] = useState(false);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setOpenMenu(open);
  };
  const list = (anchor) => (
    <div
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        {["Inbox", "Starred", "Send email", "Drafts"].map((text, index) => (
          <ListItem button key={text}>
            {/* <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon> */}
            <ListItemText primary={text} />
          </ListItem>
        ))}
      </List>
      <Divider />
      <List>
        {["All mail", "Trash", "Spam"].map((text, index) => (
          <ListItem button key={text}>
            {/* <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon> */}
            <ListItemText primary={text} />
          </ListItem>
        ))}
      </List>
    </div>
  );
  return (
    <div className="homeheader">
      <Container>
        <div className={classes.root}>
          <AppBar className={classes.appBar} position="static">
            <Toolbar className={classes.toolbar}>
              <span
                style={{ cursor: "pointer" }}
                onClick={() => {
                  window.location.href("/");
                }}
                className={classes.title}
                align="left"
              >
                Vigress
              </span>

              {showLogin && (
                <Button
                  color="primary"
                  onClick={() => {
                    window.location.href = "/login";
                  }}
                >
                  Login
                </Button>
              )}
              {showSingUp && (
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    window.location.href = "/signup";
                  }}
                >
                  Sign Up
                </Button>
              )}
            </Toolbar>
            <Drawer anchor="left" open={openMenu} onClose={toggleDrawer(false)}>
              {list()}
            </Drawer>
          </AppBar>
        </div>
      </Container>
    </div>
  );
}

export default HomeHeader;
