import {
  // CREATE_PARTNER_SUCCESS,
  // GET_USER_SUCCESS,
  SUBMIT_LOGIN_SUCCESS,
  FORCE_LOGIN_MODAL,
  CLOSE_LOGIN_MODAL,
  LOGOUT_USER,
} from "../actions/types";

const initialState = {
  loggedInName: "",
};

export default function users(state = initialState, action) {
  switch (action.type) {
    case "GET_USER_SUCCESS": {
      const { email, address } = action.payload;
      return {
        ...state,
        email,
        address,
      };
    }

    case "CREATE_PARTNER_SUCCESS": {
      const { email } = action.payload;
      return {
        ...state,
        email: email,
      };
    }
    case SUBMIT_LOGIN_SUCCESS: {
      const {
        companyName,
        _id,
        email,
        token,
        firstTimeLogin,
        firstName,
      } = action.payload;
      return {
        ...state,
        loggedInName: companyName || firstName,
        firstTimeLogin: firstTimeLogin,
        companyId: _id,
        email,
        token,
      };
    }
    case LOGOUT_USER: {
      return {};
    }
    case FORCE_LOGIN_MODAL: {
      return { ...state, forceLogin: true };
    }
    case CLOSE_LOGIN_MODAL: {
      return { ...state, forceLogin: false };
    }
    case "SET_CURRENT_BOOKING_ID": {
      return { ...state, bookingId: action.payload };
    }
    default:
      return state;
  }
}
