import {
  SAVE_SPREAD_FAIL,
  SAVE_SPREAD_SUCCESS,
  SAVE_SPREAD_START,
} from "./types";

export const saveSpreadFail = (error) => ({
  type: SAVE_SPREAD_FAIL,
  payload: error,
});
export const saveSpreadSuccess = (data) => ({
  type: SAVE_SPREAD_SUCCESS,
  payload: data,
});

export const saveSpreadStart = () => ({
  type: SAVE_SPREAD_START,
});

export const saveStatusSuccess = (data) => ({
  type: "SAVE_STATUS_SUCCESS",
  payload: data,
});
export const setNoOfSegments = (noOfSegments) => ({
  type: "SET_NO_OF_SEGMENTS",
  payload: noOfSegments,
});

export const setSelectedSpread = (data) => ({
  type: "SET_SELECTED_SPREAD",
  payload: data,
});

export const setSelectedDashboardType = (data) => ({
  type: "SET_SELECTED_DASHBOARD_TYPE",
  payload: data,
});

export const setActivityPercentMap = (data) => ({
  type: "SET_ACTIVITY_PERCENT_MAP",
  payload: data,
});
export const setStartDate = (data) => ({
  type: "SET_START_DATE",
  payload: data,
});
export const setEndDate = (data) => ({
  type: "SET_END_DATE",
  payload: data,
});
export const setProjectProgress = (data) => ({
  type: "SET_PROJECT_PROGRESS",
  payload: data,
});

export const importStatusRecords = (data) => ({
  type: "IMPORT_STATUS_RECORDS",
  payload: data,
});
